import styled from 'styled-components/macro';
import { mediaDown } from '../../../utils/styles/media';

const Page = styled.div`
  position: relative;
  height: ${(props) =>
    props.isLoading ? 'calc( 100% - 97px - 60px )' : 'auto'};
  padding-bottom: 80px;
  min-height: calc(100% - 65px);

  ${mediaDown('md')`
    overflow:hidden;
    padding-bottom: 70px;
    min-height: calc(100% - 115px);
  `}
`;

const Error = styled.div`
  padding: 70px 0;
  text-align: center;
  font-size: 16px;

  a {
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }
`;

const Title = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 80px 0 50px;
  ${(props) => props.smallHeight && 'margin: 0px 0px 0px;'}

    ${mediaDown('md')`
      margin: 10px 0 10px;
    `}

  h1 {
    color: ${(props) => (props.greyTheme ? '#52585F' : '#000000')};
    font-size: 44px;
    font-weight: 900;
    width: calc(100% - 50px);
    margin: 0;
    flex-grow: 1;

    ${mediaDown('md')`
      font-size: 24px;
      line-height: 26px;
    `}

    small {
      display: block;
      margin-top: 2px;
      color: #000000;
      font-size: 18px;
      line-height: 23px;
      font-weight: normal;

      ${mediaDown('md')`
        font-size: 12px;
        line-height: 15px;
      `}
    }
  }
`;

const BackButton = styled.div`
  width: 50px;
  height: ${(props) => (props.greyTheme ? '69px' : '60px')};
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.15s;
  ${(props) => props.greyTheme && 'align-self:center;'};
  ${mediaDown('md')`
    width: 19px;
    ${(props) => !props.greyTheme && 'margin-top: 8px;'};
    margin-right: 12px;
  `}

  &:hover {
    opacity: 0.5;

    svg {
      transform: translate(4px, 0);
    }
  }

  svg {
    transition: all 0.15s;
  }

  svg path {
    ${(props) => props.greyTheme && 'fill:#52585F;'}
  }
`;

const Addon = styled.div``;

const Styled = {
  Title,
  Error,
  BackButton,
  Page,
  Addon,
};

export default Styled;
