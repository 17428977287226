import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { intervalToDuration, addMinutes, addSeconds } from 'date-fns';
import Modal from '../Modal';
import Button from '../Button';
import Container from '../Container';
import { useCartState, useCartDispatch } from '../../context/CartContext';
import useFetch from 'use-http';
import Styled from './TimeSlotSession.styles';
import useTimeslotApi from '../../hooks/useTimeslotApi';
import SessionStorage from '../../constants/sessionStorage';
import { useUserDispatch } from '../../context/UserContext';
import moment from 'moment';
import { exponeaCapture } from '../../hooks/exponeaCapture';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
// const HEADER_HEIGHT = 97;
const SESSION_DURATION = 20;
const SHOW_ALERT_AT = 5;

const TimeSlotSession = () => {
  const { t } = useTranslation();
  const [time, setTime] = useState(undefined);
  const [showWaring, setShowWarning] = useState(false);
  const [showEmptyCartMessage, setShowEmptyCartMessage] = useState(false);
  const [showCloseToVisitMessage, setShowCloseToVisitMessage] = useState(true);
  const [timeSlotTime, setTimeSlotTime] = useState();
  const { removeServices, extendTime, loading } = useTimeslotApi();
  const userDispatch = useUserDispatch();
  const dispatch = useCartDispatch();
  const interval = useRef();
  const history = useHistory();
  const { request: timeRequest } = useFetch(`/TimeSlots`);
  const { registrationServices, startedAt, serviceDuration, timeSlotDate } =
    useCartState();

  function stopInterval() {
    clearInterval(interval.current);
  }

  function doActionAfterTimeExpiration() {
    stopInterval();
    setShowWarning(false);
    removeServices();
    setShowEmptyCartMessage(true);
  }

  useEffect(() => {
    if (!startedAt) {
      setTime(undefined);
      setShowWarning(false);
      const timeSlotToRemove = SessionStorage.getContent().timeSlot;
      var leftToPaysera = SessionStorage.getContent().left;
      var redirectUrl = SessionStorage.getContent().redirectUrl;
      if (timeSlotToRemove && !leftToPaysera) {
        if (history.location.pathname != '/' 
          && history.location.pathname != '/account'
        ) {
          history.push(redirectUrl);
        }
        timeRequest.delete(`/Reservation/${timeSlotToRemove}?additionalData=triggeredAt:TimeslotSession; historyLocation:${history.location.pathname}`);
        //for debuging purposes. Temporary po-706
        exponeaCapture(
          [
            {
              name: 'booking_stage',
              value: 'Booking cancelled',
            },
          ],
          'booking_start',
        );
        dispatch({
          type: 'RESET_CART',
        });
        userDispatch({
          type: 'REMOVE_TIMESLOT',
          timeSlotId: undefined,
        });

        userDispatch({
          type: 'SET_LEFT_CART_PAGE',
          left: false,
        });
      }
    }
  }, [startedAt]);

  useLayoutEffect(() => {
    if (startedAt) {
      interval.current = setInterval(() => {
        let duration;

        const startDate = new Date(startedAt);
        const timeSlotStartDate = new Date(timeSlotDate);

        var start = moment(startDate);
        var end = moment(timeSlotStartDate);

        if (end.diff(start.add(serviceDuration, 'minutes'), 'minutes') <= 20) {
          setTimeSlotTime(
            end.subtract(serviceDuration, 'minutes').format('HH:mm').toString(),
          );

          const durationStart = new Date();
          const durationEnd = addSeconds(
            new Date(startedAt).setMinutes(
              new Date(startedAt).getMinutes() + serviceDuration,
            ),
            end.diff(start, 'seconds'),
          )

          if (durationStart >= durationEnd) {
            doActionAfterTimeExpiration();
          }

          duration = intervalToDuration({
            start: durationStart,
            end: durationEnd
          });

          setTime(`${duration.minutes}:${('00' + duration.seconds).slice(-2)}`);
        } else {
          setShowCloseToVisitMessage(false);

          const durationStart = new Date();
          const durationEnd = addMinutes(new Date(startedAt), SESSION_DURATION)

          if (durationStart >= durationEnd) {
            doActionAfterTimeExpiration();
          }

          duration = intervalToDuration({
            start: durationStart,
            end: durationEnd
          });


          setTime(`${duration.minutes}:${('00' + duration.seconds).slice(-2)} `);
        }

        if (
          (duration.minutes === 0 && duration.seconds < 2) ||
          new Date().getTime() >=
            new Date(
              addMinutes(new Date(startedAt), SESSION_DURATION),
            ).getTime()
        ) {
          doActionAfterTimeExpiration();
        } else if (
          duration.minutes <= SHOW_ALERT_AT &&
          !showCloseToVisitMessage
        ) {
          if (
            end.diff(start.add(serviceDuration, 'minutes'), 'minutes') <= 20
          ) {
            setShowWarning(false);
          } else {
            setShowWarning(true);
          }
        } else {
          setShowWarning(false);
        }
      }, 1000);
    } else {
      stopInterval();
    }

    return () => {
      stopInterval();
    };
  }, [
    registrationServices.length,
    removeServices,
    serviceDuration,
    setTime,
    setShowCloseToVisitMessage,
    showWaring,
    setShowWarning,
    startedAt,
    timeSlotDate,
    showCloseToVisitMessage,
  ]);

  useLayoutEffect(() => {});

  return (
    <>
      {!!time && (
        <Styled.TimeSlotSession>
          <Container>
            <Styled.Message>
              {showCloseToVisitMessage &&
                `Užsakymas turi būti pateiktas iki ${timeSlotTime} `}
              {t('timeSession.stickyMessage')} <strong>{time}</strong>
            </Styled.Message>
          </Container>
        </Styled.TimeSlotSession>
      )}

      <Modal visible={showWaring} showClose={false} onClose={() => {}}>
        <Styled.Message block>
          <Trans
            i18nKey="timeSession.warningModal.title"
            values={{ time }}
            components={{ bold: <strong /> }}
          />
        </Styled.Message>

        <Modal.TextContent>
          {t('timeSession.warningModal.description')}
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            variant="secondary"
            onClick={removeServices}
            disabled={loading}
          >
            {t('timeSession.warningModal.cancelButton')}
          </Button>
          <Button
            size="large"
            onClick={() => {
              stopInterval();
              extendTime();
            }}
            disabled={loading}
          >
            {t('timeSession.warningModal.extendButton')}
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        visible={showEmptyCartMessage}
        onClose={() => setShowEmptyCartMessage(false)}
      >
        <Styled.Message block>
          {t('timeSession.expiredModal.title')}
        </Styled.Message>
        <Modal.TextContent>
          {t('timeSession.expiredModal.description')}
        </Modal.TextContent>
        <Modal.Actions>
          <Button size="large" onClick={() => setShowEmptyCartMessage(false)}>
            {t('timeSession.expiredModal.closeButton')}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default TimeSlotSession;
