import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ReactComponent as FlagLtIcon } from '../../../../assets/icons/flag-lt.svg';
import { ReactComponent as FlagEnIcon } from '../../../../assets/icons/flag-en.svg';
import { ReactComponent as FlagRuIcon } from '../../../../assets/icons/flag-ru.svg';
import Styled from './Description.styles';
import { Money } from '../../../shared/Money';
import { useSettingsState } from '../../../context/SettingsContext';
import { useUserState } from '../../../context/UserContext';
import { ReactComponent as TlkIcon } from '../../../../assets/icons/tlk.svg';
import Tooltip from 'react-tooltip';
import { FiInfo } from 'react-icons/fi';
import { CgProfile } from 'react-icons/cg';
import './Description.css';
import MultipleLaboratoryServicesSummary from '../../../shared/MultipleLaboratoryServicesSummary';
import InfoTooltip from '../../../shared/InfoTooltip/InfoTooltip';
const Description = ({
  service,
  widget,
  showPrice,
  step,
  isExtraTimeSlot,
  isPsdfTimeSlot,
  is3T,
  isHalfMrt,
  otherServices,
  selectedTimeslot,
  isLaboratoryServices,
  laboratoryMultipleServices,
  removeFromLaboratoryMultipleServicesCallback,
  addMoreLaboratoryServicesButton,
}) => {
  const { t } = useTranslation();
  const { getServiceImage } = useSettingsState();
  const userState = useUserState();
  const [showList, setShowList] = useState(false);
  const mockServices = ['Service1', 'Service2', 'Service3'];
  const isService = !service.specialistId;
  const mrt3tPriceChangeDate = new Date(
    process.env.REACT_APP_3T_PRICE_CHANGE_DATE,
  );

  const gastroenterologyServiceTypeExternalId =
    process.env.REACT_APP_GASTROENTEROLOGY_SERVICE_TYPE_EXTERNAL_ID;

  const SGSpecialtyExternalId = process.env.REACT_APP_SG_SPECIALTY_EXTERNAL_ID;

  const languages = [
    { key: 'RU', icon: <FlagRuIcon /> },
    { key: 'EN', icon: <FlagEnIcon /> },
    { key: 'LT', icon: <FlagLtIcon /> },
  ];
  const isDiscount = userState.discountCard;

  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(true);
  };

  function getAdditionalPrice() {
    let sum = 0;
    if (isLaboratoryServices) return sum;
    service.additionalServices.forEach((as) => {
      sum += as.price;
    });
    return sum;
  }

  const onLeave = () => {
    setHover(false);
  };

  const getSortedLanguages = () => {
    const languagesOrder = ['LT', 'EN', 'RU', 'DE', 'PL'];
    const customSort = (a, b) => {
      const indexA = languagesOrder.indexOf(a.toUpperCase());
      const indexB = languagesOrder.indexOf(b.toUpperCase());

      // If one of the languages is not in the predefined order, move it to the end
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;

      return indexA - indexB;
    };

    return service.languages.sort(customSort);
  };

  const shouldShowMultipleLaboratoryServicesSummary = () => {
    const payRule = selectedTimeslot?.isPaid ? 2 : 1;
    return (
      isLaboratoryServices &&
      (laboratoryMultipleServices?.length > 0 ||
        (service.additionalServices?.length > 0 &&
          service.automationRuleCodes?.some((rule) => rule === payRule)))
    );
  };

  const shouldShowAdditionalServices = () => {
    if (shouldShowMultipleLaboratoryServicesSummary()) {
      return true;
    }
    if (!service || !service.serviceTypeExternalId) {
      return false;
    }
    return (
      service.additionalServices?.length > 0 &&
      gastroenterologyServiceTypeExternalId == service.serviceTypeExternalId
    );
  };

  return (
    <>
      {shouldShowAdditionalServices() && (
        <MultipleLaboratoryServicesSummary
          serviceImage={getServiceImage(service)}
          allServices={[service, ...(laboratoryMultipleServices ?? [])]}
          isExtraVisit={isExtraTimeSlot}
          removeFromLaboratoryMultipleServicesCallback={
            removeFromLaboratoryMultipleServicesCallback
          }
          isPaid={selectedTimeslot?.isPaid}
          addMoreLaboratoryServicesButton={addMoreLaboratoryServicesButton}
          step={step}
        />
      )}
      {!shouldShowAdditionalServices() && (
        <>
          {!widget && (
            <Styled.Description>
              <Styled.Image
                style={{ backgroundImage: getServiceImage(service) }}
              />
              <Styled.Content>
                {!service.specialistLink?.length > 0 &&
                  !service.serviceLink?.length > 0 && (
                    <Styled.Title widget={widget}>
                      {!isService
                        ? `${service.specialistName} - ${service.serviceTitle}`
                        : `${service.serviceTitle}`}
                    </Styled.Title>
                  )}

                {service.specialistLink?.length > 0 &&
                  !service.serviceLink?.length > 0 && (
                    <Styled.Title widget={widget}>
                      <InfoTooltip
                        link={service.specialistLink}
                        onHover={onHover}
                        onLeave={onLeave}
                        tooltiptext={'Informacija apie gydytoją'}
                        text={
                          !isService
                            ? `${service.specialistName} - ${service.serviceTitle}`
                            : `${service.serviceTitle}`
                        }
                        Icon={CgProfile}
                        color={'green'}
                      />
                    </Styled.Title>
                  )}

                {!service.specialistLink?.length > 0 &&
                  service.serviceLink?.length && (
                    <>
                      <Styled.Title widget={widget}>
                        {!isService ? `${service.specialistName}` : ``}
                      </Styled.Title>
                      <Styled.Title widget={widget}>
                        <InfoTooltip
                          link={service.serviceLink}
                          onHover={onHover}
                          onLeave={onLeave}
                          tooltiptext={'Informacija apie paslaugą'}
                          text={`${service.serviceTitle}`}
                          Icon={FiInfo}
                        />
                      </Styled.Title>
                    </>
                  )}

                {service.specialistLink?.length > 0 &&
                  service.serviceLink?.length && (
                    <>
                      <Styled.Title widget={widget}>
                        <InfoTooltip
                          link={service.specialistLink}
                          onHover={onHover}
                          onLeave={onLeave}
                          tooltiptext={'Informacija apie gydytoją'}
                          text={`${service.specialistName}`}
                          Icon={CgProfile}
                          color={'green'}
                        />
                      </Styled.Title>
                      <Styled.Title widget={widget}>
                        <InfoTooltip
                          link={service.serviceLink}
                          onHover={onHover}
                          onLeave={onLeave}
                          tooltiptext={'Informacija apie paslaugą'}
                          text={`${service.serviceTitle}`}
                          Icon={FiInfo}
                        />
                      </Styled.Title>
                    </>
                  )}

                <Tooltip
                  id="specialist"
                  place="top"
                  effect="solid"
                  clickable={true}
                  delayHide={1000}
                  event="click"
                  className="tooltip"
                >
                  <Trans
                    i18nKey={
                      is3T
                        ? 'registration.3TTooltip'
                        : service.specialtyExternalId == SGSpecialtyExternalId
                        ? 'registration.sgTlkTooltip'
                        : 'registration.tlkTooltip'
                    }
                    components={{
                      a: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          href="https://storage.googleapis.com/afffideaglobal/SOP_LC_LT_064_Affidea_kliniku_ir_DC_Vidaus_tvarkos_taisykles_v_1_0_89bb60397d/SOP_LC_LT_064_Affidea_kliniku_ir_DC_Vidaus_tvarkos_taisykles_v_1_0_89bb60397d.pdf"
                          target="_parent"
                        />
                      ),
                    }}
                  />
                </Tooltip>

                {service.cardTitle ? (
                  <Styled.Badge widget={widget} isService={isService}>
                    {isService ? service.serviceTypeTitle : service.specialty}
                  </Styled.Badge>
                ) : (
                  <Styled.SpaceDiv></Styled.SpaceDiv>
                )}
                {/* <Styled.Properties>
          <div>
            {service.departmentTitle && <span>{t('common.workplace')}</span>}
            <span>{t('common.price')}</span>
            <span className="blank"></span>
            {service.serviceTypeTitle === "Laboratoriniai tyrimai" && (                
            <span className="blank"></span>
              )}
            {service.languages.length > 0 && !isService && (
              <span>{t('common.availableLanguages')}</span>
            )}
            {service.departmentTitle && <div>{service.departmentTitle}</div>}
            <div>
              {(isDiscount && service.priceWithDiscount !== service.price && (
                <div>
                  <strong>
                    <Money amount={service.priceWithDiscount} />
                  </strong>{' '}
                  (kaina su jums pritaikyta nuolaida){' '}
                  <del>
                    <Money amount={service.price} />
                  </del>
                </div>
              )) || (
                <strong>
                  <Money amount={service.price} />
                </strong>
              )}
            </div>
            <div>
              <span
                dangerouslySetInnerHTML={{ __html: t('common.priceDetails') }}
              ></span>
            </div>           
            {
              service.serviceTypeTitle === "Laboratoriniai tyrimai" && (
                <div>
                    <span
                dangerouslySetInnerHTML={{ __html: t('common.laboratoryVisits') }}
                   ></span>
                </div>
              )
            }
            {service.languages.length > 0 && !isService && (
              <div>
                <Styled.Flags>
                  {service.languages?.map((key) => (
                    <React.Fragment key={key}>
                      {languages.find((l) => l.key === key)?.icon || null}
                    </React.Fragment>
                  ))}
                </Styled.Flags>
              </div>
            )}
          </div>
        </Styled.Properties> */}
                {!widget && (
                  <>
                    <Styled.DescriptionWrapper psdf={service.psdf}>
                      <Styled.InfoBlock>
                        {service.departmentTitle && t('common.workplace')}
                        <span>
                          {service.departmentTitle && service.departmentTitle}
                        </span>
                      </Styled.InfoBlock>
                      {!isHalfMrt && (
                        <Styled.InfoBlock>
                          {(service.priceWithDiscount !== service.price &&
                            service.price !== 0 &&
                            service.discountType === 'NuolaiduKortele' &&
                            !isExtraTimeSlot &&
                            'Kaina su Affidea nuolaidų kortele') ||
                            (service.discountType === 'Akcijos' &&
                              !isHalfMrt &&
                              'Akcijos kaina') ||
                            (isExtraTimeSlot &&
                              'Papildomo mokamo laiko kaina') ||
                            'Standartinė kaina'}
                          {!isExtraTimeSlot && (
                            <Styled.Price>
                              {(service.priceWithDiscount !== service.price &&
                                service.price !== 0 && (
                                  <div>
                                    <strong>
                                      <Money
                                        amount={service.priceWithDiscount}
                                      />
                                    </strong>
                                    <Styled.OldPrice>
                                      <Money amount={service.price} />
                                    </Styled.OldPrice>
                                  </div>
                                )) || (
                                <strong>
                                  <Money amount={service.priceWithDiscount} />
                                </strong>
                              )}
                            </Styled.Price>
                          )}

                          {isExtraTimeSlot && (
                            <Styled.Price>
                              <strong>
                                <Money amount={service.extraPrice} />
                              </strong>
                            </Styled.Price>
                          )}
                        </Styled.InfoBlock>
                      )}
                      {service.psdf && service.nearestPsdfDate && (
                        <Styled.InfoBlock>
                          {/* <Styled.TlkIconSvg>
                            <TlkIcon />
                          </Styled.TlkIconSvg> */}
                          <p data-tip data-for="tlkPrice">
                            <FiInfo />{' '}
                            {service.specialtyExternalId ==
                            SGSpecialtyExternalId
                              ? 'Kaina prisirašiusiems prie gydymo įstaigos'
                              : 'Kaina turint siuntimą'}
                          </p>
                          <Styled.TlkPrice>
                            <Money
                              className="price"
                              amount={getAdditionalPrice()}
                            ></Money>
                          </Styled.TlkPrice>
                        </Styled.InfoBlock>
                      )}
                    </Styled.DescriptionWrapper>
                    <Tooltip
                      id="tlkPrice"
                      place="top"
                      effect="solid"
                      clickable={true}
                      delayHide={1000}
                      event="click"
                      className="tooltip"
                    >
                      <Trans
                        i18nKey={
                          is3T
                            ? 'registration.3TTooltip'
                            : service.specialtyExternalId ==
                              SGSpecialtyExternalId
                            ? 'registration.sgTlkTooltip'
                            : 'registration.tlkTooltip'
                        }
                        components={{
                          a: (
                            // eslint-disable-next-line jsx-a11y/anchor-has-content
                            <a
                              href="https://storage.googleapis.com/afffideaglobal/SOP_LC_LT_064_Affidea_kliniku_ir_DC_Vidaus_tvarkos_taisykles_v_1_0_89bb60397d/SOP_LC_LT_064_Affidea_kliniku_ir_DC_Vidaus_tvarkos_taisykles_v_1_0_89bb60397d.pdf"
                              target="_parent"
                            />
                          ),
                        }}
                      />
                    </Tooltip>

                    <Styled.AdditionalInformation>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('common.priceDetails'),
                        }}
                      ></span>
                    </Styled.AdditionalInformation>
                    {isLaboratoryServices && (
                      <Styled.AdditionalInformation>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t('common.multipleServicesInfo'),
                          }}
                        ></span>
                      </Styled.AdditionalInformation>
                    )}
                    {otherServices &&
                      otherServices.serviceShortInfo &&
                      otherServices.serviceShortInfo.length > 0 &&
                      otherServices.serviceShortInfo.some(
                        (os) => os.price > 0,
                      ) && (
                        <Styled.AdditionalServices>
                          {t('common.extra')}{' '}
                          <span>
                            <Styled.SecondaryLink
                              onClick={() => setShowList((prev) => !prev)}
                            >
                              {showList ? '(suskleisti)' : 'čia'}
                            </Styled.SecondaryLink>
                            {showList && (
                              <table
                                style={{
                                  color: '#000',
                                  padding: 'Opx',
                                  marginLeft: '-3px',
                                  border: '0',
                                }}
                              >
                                {otherServices.serviceShortInfo
                                  .filter((os) => os.price > 0)
                                  .map((os, idx) => {
                                    return (
                                      <tr>
                                        <td
                                          style={{
                                            color: '#000',
                                            padding: 'Opx',
                                            margin: '0px',
                                            border: '0',
                                          }}
                                        >
                                          {os.title}
                                        </td>
                                        <td
                                          style={{
                                            color: '#000',
                                            padding: 'Opx',
                                            margin: '0px',
                                            border: '0',
                                            textAlign: 'right',
                                          }}
                                        >
                                          {os.price}€
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </table>
                            )}
                            {showList && (
                              <Styled.Backdrop
                                onClick={() => setShowList(false)}
                              ></Styled.Backdrop>
                            )}
                          </span>
                        </Styled.AdditionalServices>
                      )}
                    {/* <ul class="menu"> */}

                    {!!service.languages?.length && (
                      <Styled.AdditionalServices>
                        {`Kalbos: ${getSortedLanguages().join(', ')}`}
                      </Styled.AdditionalServices>
                    )}

                    {/* </ul> */}
                    {step > 0 && addMoreLaboratoryServicesButton}
                  </>
                )}
              </Styled.Content>
            </Styled.Description>
          )}
          {widget && (
            <Styled.Description isWidget={true}>
              <Styled.WidgetImageResponsive
                style={{ backgroundImage: getServiceImage(service) }}
              />
              <Styled.ContentResponsive>
                <div>
                  <Styled.WidgetTitle widget={widget}>
                    {isService
                      ? service.serviceTitle
                      : service.specialistName + ' - ' + service.serviceTitle}
                  </Styled.WidgetTitle>

                  {service.cardTitle && (
                    <Styled.Badge isService={isService}>
                      {isService ? service.serviceTypeTitle : service.specialty}
                    </Styled.Badge>
                  )}
                  {showPrice && !isHalfMrt && (
                    <Styled.WidgetPrice>
                      Kaina:{' '}
                      <span>
                        {' '}
                        <Money amount={service.priceWithDiscount} />
                      </span>
                    </Styled.WidgetPrice>
                  )}
                </div>
                <Styled.TimeSlotLegend>
                  <Styled.TimeSlotInfo>
                    <Styled.TimeSlotResponsive>
                      <Styled.Slot legend={true}></Styled.Slot>
                    </Styled.TimeSlotResponsive>
                    <Styled.LegendTitleResponsive>
                      Mokami laikai
                    </Styled.LegendTitleResponsive>
                  </Styled.TimeSlotInfo>

                  <Styled.TimeSlotInfo>
                    <Styled.TimeSlotResponsive>
                      <Styled.Slot isPaid={true} legend={true}>
                        <Styled.TlkIconSvg>
                          <TlkIcon />
                        </Styled.TlkIconSvg>
                      </Styled.Slot>
                    </Styled.TimeSlotResponsive>
                    <Styled.LegendTitleResponsive isPaid={true}>
                      Kompensuoja PSDF
                    </Styled.LegendTitleResponsive>
                  </Styled.TimeSlotInfo>
                </Styled.TimeSlotLegend>
              </Styled.ContentResponsive>
            </Styled.Description>
          )}
        </>
      )}
    </>
  );
};

export default Description;
