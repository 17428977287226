import React, { useEffect, useState } from 'react';
import { parseISO, format as dateFnsFormat } from 'date-fns';
import Button from '../../../shared/Button';
import { ReactComponent as CrossIcon } from '../../../../assets/icons/cross.svg';
import { useSettingsState } from '../../../context/SettingsContext';
import Styled from './Summary.styles';
import { Money, formatCurrency } from '../../../shared/Money';
import CouponImg from '../../../../assets/images/Dovanu_kuponas.jpg';
import Tooltip from 'react-tooltip';
import { useTranslation, Trans } from 'react-i18next';
import { FiInfo } from 'react-icons/fi';
import './Summary.css';
import { exponeaCapture } from '../../../hooks/exponeaCapture';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const Summary = ({ item, onCountChange }) => {
  const [selectedCount, setSelectedCount] = useState(1);
  const history = useHistory();
  function numberInput(number) {
    if (number > 99) {
      setSelectedCount(99);
      onCountChange(99);
    } else {
      setSelectedCount(number);
      onCountChange(number);
    }
  }

  useEffect(() => {
    exponeaCapture(
      [
        { name: 'category_1', value: 'Dovanų kuponai' },
        { name: 'category_2', value: item.title },
        { name: 'product_id', value: item.serviceId },
        { name: 'category_id', value: 3 },
      ],
      'view_item',
    );
  }, []);

  return (
    <Styled.Summary>
      <Styled.LabelsList>
        <div>Paslauga</div>
        <div>Pastaba</div>
        <div>Galioja iki</div>
        <div>Kaina</div>
      </Styled.LabelsList>
      <Styled.Item>
        <Styled.Image
          desktop={true}
          style={{
            backgroundImage: `url(${CouponImg})`,
          }}
        />
        <Styled.ItemContent>
          <Styled.MainInfo>
            <Styled.Text
              size="large"
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <p
                style={{
                  marginTop: '2px',
                  marginRight: '5px',
                }}
                data-tip
                data-for="coupon"
              >
                <FiInfo style={{ color: 'rgb(95, 157, 244)' }} />
              </p>
              <Tooltip
                id="coupon"
                place="top"
                effect="solid"
                clickable={true}
                delayHide={1000}
                className="tooltip"
              >
                Su dovanų kupono įsigijimo ir naudojimo taisyklėmis galite
                susipažinti{' '}
                <a
                  href="https://affidea.lt/lt/paslaugos/dovanu-kuponai/"
                  target="_blank"
                >
                  čia
                </a>
              </Tooltip>{' '}
              {item.title}
            </Styled.Text>
            <Styled.ImageMobile
              mobile={true}
              style={{
                backgroundImage: `url(${CouponImg})`,
                width: '60px',
              }}
            />
          </Styled.MainInfo>
          <div>
            <Styled.Text data-label="Pastaba">{item.comment}</Styled.Text>
          </div>
          <div>
            <Styled.Text data-label="Galioja iki">{item.validTo}</Styled.Text>
          </div>
          <div>
            <Styled.Text data-label="Kaina" size="price">
              <Money amount={item.price} />
            </Styled.Text>
          </div>
          <Styled.Number>
            <label>Kiekis:</label>
            <input
              type="number"
              min="1"
              max="99"
              value={selectedCount}
              onChange={(value) => numberInput(value.target.value)}
            />
          </Styled.Number>
        </Styled.ItemContent>
      </Styled.Item>
      <Styled.TotalPrice>
        Iš viso su PVM: {formatCurrency(item.price * selectedCount)}
      </Styled.TotalPrice>
    </Styled.Summary>
  );
};

export default Summary;
