import affideaLogo from './assets/images/affidea-logo.svg';

import cat1Img from './assets/images/cat-1.png';
import cat2Img from './assets/images/cat-2.png';
import cat3Img from './assets/images/cat-3.png';

function env(original, fallback, parse = false) {
  if (original) {
    return parse ? JSON.parse(original) : original;
  }
  return fallback;
}

const clients = [
  {
    id: env(
      process.env.REACT_APP_CLIENT_ID,
      '01e91ca5-09d8-441e-ba70-df04326a591b',
    ),
    phoneNumber: env(process.env.REACT_APP_PHONE_NUMBER, '+370 5 244 1188'),
    shortPhoneNumber: env(process.env.REACT_APP_SHORT_PHONE_NUMBER, '1811'),
    theme: {
      primaryColor: env(process.env.REACT_APP_PRIMARY_COLOR, '#0087F7'),
      brandColor: '#4181D0',
      logo: env(process.env.REACT_APP_LOGO, affideaLogo),
      categories: env(
        process.env.REACT_APP_CATEGORIES_IMAGES,
        {
          1: cat1Img,
          2: cat2Img,
          3: cat3Img,
        },
        true,
      ),
    },
    header: {
      about: {
        url: env(
          process.env.REACT_APP_HEADER_URL,
          'https://affidea.lt/lt/apie-affidea/',
        ),
      },
      services: {
        url: env(
          process.env.REACT_APP_HEADER_URL,
          'https://affidea.lt/lt/paslaugos/',
        ),
      },
      findUs: {
        url: env(
          process.env.REACT_APP_HEADER_URL,
          'https://affidea.lt/lt/klinikos/',
        ),
      },
      ourDoctors: {
        url: env(
          process.env.REACT_APP_HEADER_URL,
          'https://affidea.lt/lt/gydytojai/',
        ),
      },
      prices: {
        url: env(
          process.env.REACT_APP_HEADER_URL,
          'https://affidea.lt/lt/kainos/',
        ),
      },
    },
    footer: {
      cookiePolicy: {
        url: env(
          process.env.REACT_APP_FOOTER_URL,
          'https://affidea.lt/lt/slapuku-politika/',
        ),
      },
      privacyPolicy: {
        url: env(
          process.env.REACT_APP_FOOTER_URL,
          'https://affidea.lt/lt/svetaines-privatumo-pranesimas/',
        ),
      },
      termsOfUse: {
        url: env(
          process.env.REACT_APP_FOOTER_URL,
          'https://storage.googleapis.com/afffideaglobal/SOP_LC_LT_042_Portalo_mano_affidea_lt_naudojimo_taisykles_v1_1_1_e73d9925cb/SOP_LC_LT_042_Portalo_mano_affidea_lt_naudojimo_taisykles_v1_1_1_e73d9925cb.pdf',
        ),
      },
    },
    agreements: {
      rules: {
        url: env(
          process.env.REACT_APP_RULES_URL,
          'https://storage.googleapis.com/afffideaglobal/Nuotoliniu_sveikatos_prieziuros_paslaugu_teikimo_tvarka_b8cac803ac/Nuotoliniu_sveikatos_prieziuros_paslaugu_teikimo_tvarka_b8cac803ac.pdf',
        ),
      },
      personalData: {
        url: env(
          process.env.REACT_APP_PERSONLA_DATA_URL,
          'https://storage.googleapis.com/afffideaglobal/Privatumo_pranesimas_PACIENTAMS_LT_V12_2024_07_23_bc1b2f4d2f/Privatumo_pranesimas_PACIENTAMS_LT_V12_2024_07_23_bc1b2f4d2f.pdf',
        ),
      },
      termsOfUse: {
        url: env(
          process.env.REACT_APP_PERSONLA_DATA_URL,
          'https://storage.googleapis.com/afffideaglobal/SOP_LC_LT_042_Portalo_mano_affidea_lt_naudojimo_taisykles_v1_1_1_e73d9925cb/SOP_LC_LT_042_Portalo_mano_affidea_lt_naudojimo_taisykles_v1_1_1_e73d9925cb.pdf',
        ),
      },
      couponRules: {
        url: env(
          process.env.REACT_APP_COUPON_RULES_URL,
          'https://affidea.lt/lt/paslaugos/dovanu-kuponai/',
        ),
      },
    },
    cookies: {
      enabled: env(
        process.env.REACT_APP_COOKIES_ENABLED,
        ['mandatory', 'functional', 'analysis', 'marketing'],
        true,
      ),
      url: env(
        process.env.REACT_APP_COOKIES_URL,
        'https://storage.googleapis.com/afffideaglobal/Duomenu_apsaugos_politika_1729df193a/Duomenu_apsaugos_politika_1729df193a.pdf',
      ),
    },
  },
];

export default clients;
