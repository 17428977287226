import { useCartState, useCartDispatch } from '../context/CartContext';
import { useUserDispatch, useUserState } from '../context/UserContext';
import useFetch from 'use-http';
import { useHistory } from 'react-router-dom';
const useRedirection = () => {
  const dispatch = useCartDispatch();
  const cartState = useCartState();
  const history = useHistory();
  const userState = useUserState();
  const sessionDispatch = useUserDispatch();
  const isDirect = history?.location?.state?.isDirect;
  const {
    get: getServiceWithDiscount,
    response: getServiceWithDiscountResponse,
  } = useFetch(`/Services/serviceIdWithDiscount`);

  const clearCartState = () => {
    const urlsWhereCartStateShouldNotBeRemovedOnLeave = [
      '/selfservice',
      '/login/smartid',
      '/account',
      '/login/msignature',
      '/confirmIdentity',
      '/verify/phone',
      '/order',
      '/checkout',
      '/register',
      '/register/info',
    ];
    if (
      cartState.url &&
      !urlsWhereCartStateShouldNotBeRemovedOnLeave.includes(
        history.location.pathname,
      )
    ) {
      dispatch({
        type: 'RESET_CART',
      });
    }
  };

  const performRedirectionOnCondition = async () => {
    if (
      (cartState.orderUrl || cartState.url) &&
      history.location.pathname === '/account'
      && !isDirect
    ) {
      if (!!userState.fromUrlRedirectToLogin) {
        var redirectUrl = userState.fromUrlRedirectToLogin;
        dispatch({
          type: 'REMOVE_FROM_URL_REDIRECT_TO_LOGIN',
        });
        history.replace(redirectUrl);
      }

      let splittedUrl = undefined;
      if (cartState.url !== '/checkout') {
        splittedUrl = cartState.url.split('/');
      } else {
        splittedUrl = cartState.orderUrl.split('/');
      }

      const workplaceId = splittedUrl[2];
      const serviceId = splittedUrl[3];
      await getServiceWithDiscount(
        `?serviceId=${serviceId}&workplaceId=${workplaceId}`,
      ).then((res) => {
        dispatch({
          type: 'CHANGE_REGISTRATION_SERVICE_ID',
          newServiceId: res,
        });
        if (cartState.orderUrl) {
          history.push(`/checkout`, {
            backPath: `/order/${workplaceId}/${res}`,
          });
        } else {
          dispatch({
            type: 'REMOVE_BACK_URL',
          });

          history.push(`/order/${workplaceId}/${res}`, {
            lastStep: cartState.step,
            registrationServices: cartState.tempRegistrationServices,
          });
        }
      });
    }
  };

  const performRedirectionToLoginRedirectedFrom = async () => {
    if (history.location.pathname === '/account') {
      if (!!userState.fromUrlRedirectToLogin) {
        var redirectUrl = userState.fromUrlRedirectToLogin;
        sessionDispatch({
          type: 'REMOVE_FROM_URL_REDIRECT_TO_LOGIN',
        });
        history.replace(redirectUrl);
      }
    }
  };

  return [
    clearCartState,
    performRedirectionOnCondition,
    performRedirectionToLoginRedirectedFrom,
  ];
};
export default useRedirection;
