import React, { useCallback, useState } from 'react';
import { useSettingsState } from '../../../../context/SettingsContext';
import Styled from './CombinedServicesSummary.styles';
import { Money, formatCurrency } from '../../../../shared/Money';
import moment from 'moment';
import { useEffect } from 'react';
import { FiInfo } from 'react-icons/fi';
import { CgMenuRound } from 'react-icons/cg';
import './Summary.css';
import Tooltip from 'react-tooltip';
import { exponeaCapture } from '../../../../hooks/exponeaCapture';
import InfoTooltip from '../../../../shared/InfoTooltip/InfoTooltip';
import { useTranslation } from 'react-i18next';

const CombinedServicesSummary = ({
  item,
  price,
  discountPrice,
  onCountChange,
  selectedDepartment,
  serviceLink,
  totalPrice,
}) => {
  const { t } = useTranslation();
  const { getServiceImage } = useSettingsState();
  const [selectedPrice, setSelectedPrice] = useState();
  const [backupSelectedPrice, setBackupSelectedPrice] = useState();

  const subscriptionsServiceTypeExternalId =
    process.env.REACT_APP_SUBSCRIPTIONSEXTERNALID;

  useEffect(() => {
    if (selectedDepartment) {
      setSelectedPrice(
        price.find((x) => x.departmentId === selectedDepartment),
      );
    }
  }, [price, selectedDepartment]);

  useEffect(() => {
    exponeaCapture(
      [
        { name: 'category_1', value: 'Paslaugų paketai' },
        { name: 'category_2', value: item.title },
        { name: 'product_id', value: item.serviceId },
        { name: 'category_id', value: 3 },
      ],
      'view_item',
    );
  }, []);

  useEffect(() => {
    if (selectedPrice) {
      exponeaCapture(
        [
          { name: 'category_1', value: 'Paslaugų paketai' },
          { name: 'category_2', value: item.title },
          { name: 'Price', value: selectedPrice?.price },
          { name: 'Deparment', value: selectedDepartment },
          { name: 'product_id', value: item.serviceId },
          { name: 'category_id', value: 3 },
        ],
        'view_item',
      );
    }
  }, [selectedPrice]);

  useEffect(() => {
    if (!!discountPrice) {
      if (
        discountPrice < selectedPrice.price ||
        discountPrice < selectedPrice.originalPrice
      ) {
        setBackupSelectedPrice(selectedPrice);
        setSelectedPrice({
          ...selectedPrice,
          price: discountPrice,
        });
      }
    } else if (!!backupSelectedPrice) {
      setSelectedPrice(backupSelectedPrice);
    }
  }, [discountPrice]);

  return (
    <Styled.Summary>
      <Styled.LabelsList>
        <div>Paslauga</div>
        <div>Pastaba</div>
        <div>Galioja</div>
        <div>
          {selectedPrice?.originalPrice !== null ? 'Akcijos kaina' : 'Kaina'}
        </div>
      </Styled.LabelsList>

      <Styled.Item style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Styled.Image
            desktop={true}
            style={{
              backgroundImage: getServiceImage(item),
            }}
          />
          <Styled.ItemContent>
            <Styled.MainInfo>
              <Styled.Text
                size="large"
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                {!!serviceLink && (
                  <>
                    <InfoTooltip
                      link={serviceLink}
                      tooltiptext={'Informacija apie paslaugą'}
                      Icon={FiInfo}
                      size={22}
                    />
                  </>
                )}

                {item?.serviceType?.externalId ==
                subscriptionsServiceTypeExternalId ? (
                  <>
                    <InfoTooltip
                      link={'https://affidea.lt/abonementai/'}
                      tooltiptext={
                        'https://storage.googleapis.com/afffideaglobal/SOP_SM_LT_007_Paslaugu_abonementu_naudojimo_taisykles_v_1_0_3e57a34a17/SOP_SM_LT_007_Paslaugu_abonementu_naudojimo_taisykles_v_1_0_3e57a34a17.pdf'
                      }
                      Icon={CgMenuRound}
                      size={22}
                    />
                  </>
                ) : (
                  <InfoTooltip
                    link={
                      'https://storage.googleapis.com/afffideaglobal/Kompleksiniu_sveikatos_prieziuros_paslaugu_teikimo_tvarka_6a40e6d71c/Kompleksiniu_sveikatos_prieziuros_paslaugu_teikimo_tvarka_6a40e6d71c.pdf'
                    }
                    tooltiptext={
                      'Kompleksinių sveikatos tyrimų programų teikimo tvarka'
                    }
                    Icon={CgMenuRound}
                    size={22}
                  />
                )}

                {item.title}
              </Styled.Text>

              <Styled.ImageMobile
                mobile={true}
                style={{
                  backgroundImage: getServiceImage(item),
                  width: '60px',
                }}
              />
            </Styled.MainInfo>

            <div>
              <Styled.Text data-label="Pastaba">{item.comment}</Styled.Text>
            </div>
            <div>
              <Styled.Text data-label="Galioja iki">
                {item.validationTimeInDays} d.
              </Styled.Text>
            </div>
            <div>
              <Styled.Text data-label="Kaina" size="price">
                <Money amount={selectedPrice?.price} />
                {selectedPrice?.originalPrice !== null && (
                  <span
                    style={{
                      textDecoration: 'line-through',
                      color: 'grey',
                      fontWeight: 'normal',
                    }}
                  >
                    <Money amount={selectedPrice?.originalPrice} />
                  </span>
                )}
              </Styled.Text>
            </div>
          </Styled.ItemContent>
        </div>

        <Styled.ServicePartsDiv>
          <span>
            {item.serviceParts.map((sp) => {
              if (sp.objectServices.length > 0) {
                return (
                  <div
                    style={{ color: 'grey' }}
                    key={`objectService-${sp.amisId}`}
                  >
                    {`${sp.objectServices.map((os) => os.name).join(', ')} - ${
                      sp.amount
                    }`}
                  </div>
                );
              }
              return (
                <div
                  style={{ color: 'grey' }}
                  key={sp.amisId}
                >{`${sp.name} - ${sp.amount}`}</div>
              );
            })}
          </span>
        </Styled.ServicePartsDiv>
      </Styled.Item>
      <Styled.Notification>
        {t('landing.hero.notification')}
      </Styled.Notification>
      <Styled.TotalPrice>
        {selectedDepartment && `Iš viso su PVM: ${formatCurrency(totalPrice)}`}
        {!selectedDepartment &&
          'Pasirinkite padalinį, kad matytumėte paslaugos kainą.'}
      </Styled.TotalPrice>
    </Styled.Summary>
  );
};

export default CombinedServicesSummary;
